































import { Component, Vue } from 'vue-property-decorator'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import ConfirmationModal from '@bertazzoni/back/components/ConfirmationModal.vue'
import SideNavMenu from '@bertazzoni/back/components/SideNavMenu.vue'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import ModulesService from '@bertazzoni/common/src/services/ModulesService'
import { Mod } from '@bertazzoni/common/src/models/module.model'
import { TableInfo } from '@bertazzoni/back/models/table.model'
import { User } from '@bertazzoni/common/src/models/user.model'
import { availableLanguages } from '@bertazzoni/common/src/helpers/utils/utils'
import { isErrorNotFound } from '@bertazzoni/common/models/user.error'

@Component({
  components: { PaginatedTable, SideNavMenu, ConfirmationModal, Loader }
})
export default class ModuleList extends Vue {
  private moduleList: Array<Mod> = []
  private searchField = ''
  private language = ''
  private tableInfo: TableInfo = new TableInfo()
  private loading = false
  private pageLoaded = false
  private showDeleteModal = false
  private currentUser: User = new User()
  private selectedModule: { id: string; name: string }

  async created(): Promise<void> {
    this.tableInfo.getCustomTable().searchAndCreate = true
    await this.getModulList()
    await this.getLanguageList()
    this.pageLoaded = true
  }
  async getModulList(): Promise<void> {
    this.loading = true
    let result
    try {
      result = await ModulesService.findAllPaginated({
        limit: this.tableInfo.paginInfo.limit,
        offset: this.tableInfo.paginInfo.offset,
        title: this.searchField,
        countries: this.language
      })
    } catch (e) {
      if (!isErrorNotFound(e)) {
        this.loading = false
      }
    }

    this.moduleList = result.results
    this.tableInfo.paginInfo.totalRow = result.total
    this.loading = false
  }
  async getLanguageList(): Promise<void> {
    this.tableInfo.userRight.languageList = JSON.parse(JSON.stringify(availableLanguages))
    this.tableInfo.userRight.languageList.unshift('None')
  }
  openDeleteModal(id: string, name: string) {
    this.selectedModule = { id: id, name: name }
    this.showDeleteModal = true
  }
  goModuleConsult(id: string) {
    this.$router.push(`/module-consultation/${id}`)
  }
  goModuleEdit(module: Mod) {
    this.$router.push(`/module-update/${module._id}`)
  }
  goAddModule() {
    this.$router.push('/add-module')
  }
  async newLimit(limit: number) {
    this.tableInfo.paginInfo.limit = limit
    this.newPage(0, 1)
  }
  async newPage(offset: number, currentPage: number) {
    this.tableInfo.paginInfo.offset = offset
    this.tableInfo.paginInfo.currentPage = currentPage
    await this.getModulList()
  }
  async newSearch(searchField: string) {
    if (searchField !== this.searchField) {
      this.searchField = searchField
      this.newPage(0, 1)
    }
  }
  async newLanguage(language: string) {
    if (language !== this.language) {
      this.language = language
      this.newPage(0, 1)
    }
  }
  async deleteModule() {
    await ModulesService.removeOne(this.selectedModule.id)
    this.showDeleteModal = false
    this.newPage(0, 1)
  }
}
